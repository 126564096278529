import SvgIcon from '@mui/material/SvgIcon'

const InfoErrorIcon = () => {
  return (
    <SvgIcon
      width='147'
      height='147'
      viewBox='0 0 147 147'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: { xs: '100px', sm: '140px' } }}
    >
      <path
        d='M147 73.5C147 114.093 114.093 147 73.5 147C32.9071 147 0 114.093 0 73.5C0 32.9071 32.9071 0 73.5 0C114.093 0 147 32.9071 147 73.5Z'
        fill='#F5B400'
      />
      <path
        d='M78.4688 50.875H68.5312V41.7344H78.4688V50.875ZM77.9062 112H69.0938V59.6406H77.9062V112Z'
        fill='white'
      />
    </SvgIcon>
  )
}

export default InfoErrorIcon
