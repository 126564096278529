import { createTheme } from '@mui/material/styles'

const themeTemplate = createTheme({
  palette: {
    primary: {
      light: '#E9F9FF',
      main: '#0A98CD',
      contrastText: '#fff'
    },
    secondary: {
      main: '#F1EFEE',
      contrastText: '#000'
    },
    info: {
      main: '#F1EFEE'
    },
    dark: {
      ultraLight: '#4f4f4f',
      light: '#3d3d3d',
      main: '#282828'
    },
    whiteFont: { main: '#ffffff' }
  },
  typography: {
    fontFamily: 'Verdana,Geneva,sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  }
})

export const colors = {
  primary: {
    main: '#102027',
    contrastText: '#fff'
  },
  secondary: {
    main: '#ffb300',
    contrastText: '#000'
  },
  font: {
    main: '#F1EFEE'
  }
}

export default themeTemplate
