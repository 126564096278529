import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  successForgotPassword: null,
  errorForgotPassword: null,
  loadingForgotPassword: false,
  usernameTemp: null
}

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async ({ username }, { rejectWithValue }) => {
    try {
      await Auth.forgotPassword(username)
      return true
    } catch (err) {
      switch (err.message) {
        case 'Username/client id combination not found.':
          return rejectWithValue(
            'No existe información para el correo ingresado'
          )
        default:
          return rejectWithValue(
            'Ha ocurrido un error al intentar recuperar su contraseña.'
          )
      }
    }
  }
)

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    resetForgotPassword: (state) => {
      state.successForgotPassword = null
      state.errorForgotPassword = null
      state.loadingForgotPassword = false
      // state.usernameTemp = null
    },
    setUsernameTemp: (state, { payload }) => {
      state.usernameTemp = payload
    }
  },
  extraReducers: {
    [forgotPassword.pending]: (state) => {
      state.loadingForgotPassword = true
      state.successForgotPassword = null
      state.errorForgotPassword = null
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.loadingForgotPassword = false
      state.successForgotPassword = payload
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.loadingForgotPassword = false
      state.errorForgotPassword = payload
    }
  }
})

export const { resetForgotPassword, setUsernameTemp } =
  forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer
