import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  successSignUp: null,
  errorSignUp: null,
  loadingSignUp: false
}

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ username, password, email, name }, { rejectWithValue }) => {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          name
        }
      })
      return true
    } catch (err) {
      switch (err.message) {
        case 'An account with the given email already exists.':
          return rejectWithValue('Un usuario con este correo ya existe.')
        default:
          return rejectWithValue('Ha ocurrido un error.')
      }
    }
  }
)

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    resetSignUp: (state) => {
      state.successSignUp = null
      state.errorSignUp = null
      state.loadingSignUp = false
    }
  },
  extraReducers: {
    [signUp.pending]: (state) => {
      state.loadingSignUp = true
      state.successSignUp = null
      state.errorSignUp = null
    },
    [signUp.fulfilled]: (state, { payload }) => {
      state.loadingSignUp = false
      state.successSignUp = payload
    },
    [signUp.rejected]: (state, { payload }) => {
      state.loadingSignUp = false
      state.errorSignUp = payload
    }
  }
})

export const { resetSignUp } = signUpSlice.actions

export default signUpSlice.reducer
