import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Footer = ({ user }) => {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent='center'
      sx={{
        height: '116px',
        paddingLeft: user ? { xs: '0', sm: '64px' } : '0'
      }}
    >
      <Grid
        item
        xs={2}
        sx={{
          display: { xs: 'flex' },
          alignItems: { xs: 'center' },
          paddingTop: { xs: '50px', sm: '0' },
          marginBottom: { xs: '42px', md: '0', xl: '42px' }
        }}
        justifyContent='center'
      >
        <Box>
          <Box
            sx={{
              width: '300px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px' },
                fontWeight: '400',
                color: '#5F5F5F',
                paddingBottom: '10px',
                textAlign: 'center'
              }}
            >
              © Kyocera Document Solutions Chile
            </Typography>
            {/* <a
              href='https://hazloagil.com/'
              target='_blank'
              rel='noreferrer'
              style={{
                textDecoration: 'none'
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '10px', sm: '13px' },
                  fontWeight: '400',
                  fontStyle: 'italic',
                  fontFamily: 'Verdana,Geneva,sans-serif',
                  color: '#5F5F5F',
                  textAlign: 'center',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Power by HazloAgil
              </Typography>
            </a> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Footer
