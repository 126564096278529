import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import App from './app'
import { store, persistor } from 'redux/store'
import reportWebVitals from './report-web-vitals'
import { ApolloProvider } from '@apollo/client'
import { client } from './apollo-config'
import './amplify-config'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  dsn: process.env.REACT_APP_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  // tracesSampleRate: 1.0
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 1, // tried both 0.1 and 1.0
  replaysOnErrorSampleRate: 1.0
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ApolloProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
