import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export default function Loader () {
  return (
    <Box sx={{ height: '100vh' }} bgcolor='info.main'>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress />
      </Box>
    </Box>
  )
}
