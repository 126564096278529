import { routeName } from './helpers'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const PrivateRoute = () => {
  const { login, profile, products } = routeName
  const { user } = useSelector((state) => state.signIn)
  const { pathname } = useLocation()
  const { user: userAttributes } = useSelector((state) => state.currentUserInfo)
  const { pinTemp } = useSelector((state) => state.userTemp)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      pathname !== profile &&
      userAttributes &&
      !userAttributes['custom:pin']
    ) {
      navigate(profile)
    }
  }, [pathname, userAttributes])

  useEffect(() => {
    if (pathname === profile && pinTemp) {
      navigate(products)
    }
  }, [pathname, pinTemp])

  if (!user) return <Navigate to={login} />
  return <Outlet />
}

export default PrivateRoute
