import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  value: null
}
export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    addBalance: (state, action) => {
      state.value = action.payload.balance
    }
  }
})

export const { addBalance } = balanceSlice.actions

export default balanceSlice.reducer
