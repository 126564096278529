import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './components.css'
import { Controller } from 'react-hook-form'

const PhoneNumber = ({ control, message, value = null, required = true }) => {
  return (
    <div className='container-phone-number'>
      <Controller
        name='phone'
        control={control}
        rules={{
          validate: (phone) => {
            if (phone.length === 13 || phone.length === 11) {
              return true
            } else if (phone === '+56 ' || phone === '56') {
              return true
            } else {
              return false
            }
          }
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <>
            <PhoneInput
              {...field}
              country={'cl'}
              onlyCountries={['cl']}
              masks={{ cl: '(.) ....-....' }}
              inputExtraProps={{
                ref,
                autoFocus: true
              }}
              placeholder='phone-input'
              countryCodeEditable={false}
              disableDropdown={true}
              value={value}
            />
            <p className='error-phone-number'>
              {error ? message[error.type] : null}
            </p>
          </>
        )}
      />
    </div>
  )
}

export default PhoneNumber
