import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { routeName } from 'routes'
import UserIcon from './user-icon'
import UserMenuIcon from './user-menu-icon'
import PassMenuIcon from './pass-menu-icon'
import LogoutIcon from './logout-icon'

const Account = ({ user, logOut, loadingSignOut }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [toggle, setToggle] = useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const handleProfileMenuOpen = (e) => {
    setAnchorEl(e.currentTarget)
    setToggle(!toggle)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
    setToggle(!toggle)
  }
  const menuId = 'primary-search-account-menu'

  if (!user) return null
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '10px', sm: '0' },
          right: { xs: '21px', xl: '54px' },
          display: 'flex',
          alignItems: 'center',
          zIndex: '5000'
        }}
      >
        <IconButton
          size='large'
          edge='end'
          aria-label='account of current user'
          aria-controls={menuId}
          aria-haspopup='true'
          onClick={handleProfileMenuOpen}
          color='inherit'
          disableRipple
          sx={{ color: '#30333C' }}
        >
          <UserIcon />
        </IconButton>
      </Box>
      {toggle && (
        <MenuAccount
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          isMenuOpen={isMenuOpen}
          logOut={logOut}
          loadingSignOut={loadingSignOut}
        />
      )}
    </Box>
  )
}
const MenuAccount = ({
  anchorEl,
  handleMenuClose,
  isMenuOpen,
  logOut,
  loadingSignOut
}) => {
  const menuId = 'primary-search-account-menu-mobile'
  const { profile, updatePassword } = routeName
  const handleSignOut = () => {
    logOut()
    handleMenuClose()
  }

  const menuItems = [
    { name: 'Mi perfil', path: profile, icon: <UserMenuIcon /> },
    {
      name: 'Cambiar contraseña',
      path: updatePassword,
      icon: <PassMenuIcon />
    },
    {
      name: 'Cerrar sesión',
      logout: handleSignOut,
      disabledBtn: loadingSignOut,
      icon: <LogoutIcon />
    }
  ]
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      disableScrollLock={true}
      onClose={handleMenuClose}
      sx={{
        top: { xs: '46px', md: '52px' },
        boxShadow: '0px 4px 4px rgba(0, 0, 0, .1)'
      }}
      PaperProps={{
        style: {
          width: 275,
          height: 160,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }}
      elevation={1}
    >
      {menuItems.map(({ name, path, logout, disabledBtn, icon }) => (
        <MenuItem
          sx={{
            width: '248px',
            height: '42px',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'primary.light'
            }
          }}
          key={name}
          disableRipple
          onClick={logout}
          disabled={disabledBtn}
        >
          <Link
            to={path}
            onClick={handleMenuClose}
            style={{
              width: '100%',
              textDecoration: 'none'
            }}
          >
            <Box sx={{ height: '40px', display: 'flex', alignItems: 'center' }}>
              <Box>{icon}</Box>
              <Typography
                sx={{
                  height: '40px',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '40px',
                  paddingLeft: '10px',
                  color: '#545454'
                }}
              >
                {name}
              </Typography>
            </Box>
          </Link>
        </MenuItem>
      ))}
    </Menu>
  )
}

export default Account
