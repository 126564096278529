import SvgIcon from '@mui/material/SvgIcon'

const SuccessIcon = ({ successPaymentStatus }) => {
  return (
    <SvgIcon
      width='147'
      height='147'
      viewBox='0 0 217 217'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: { xs: '100px', sm: '130px' } }}
    >
      <circle
        cx='108.5'
        cy='108.5'
        r='108.5'
        fill={successPaymentStatus ? '#00B176' : '#0A98CD'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M159.18 83.3904L151.768 76L144.685 83.3904L86.9035 141.172L60.3991 114.36L56 109.961V124.61L86.7936 155.579L159.18 83.3904Z'
        fill='white'
      />
    </SvgIcon>
  )
}

export default SuccessIcon
