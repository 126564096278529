import { AppRoutes } from 'routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import themeTemplate from 'theme-config'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import HandleError from 'features/sentry/handle-error'

const App = () => {
  const { user } = useSelector((state) => state.currentUserInfo)

  useEffect(() => {
    if (!user) Sentry.setUser(null)
    if (user) {
      Sentry.setUser({ email: user.email })
    }
  }, [user])

  return (
    <Sentry.ErrorBoundary fallback={<HandleError />}>
      <ThemeProvider theme={themeTemplate}>
        <Router>
          <AppRoutes />
        </Router>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
