import SvgIcon from '@mui/material/SvgIcon'

const ForgotPasswordIcon = () => {
  return (
    <SvgIcon
      width='154'
      height='154'
      viewBox='0 0 154 154'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{
        fontSize: '140px',
        border: '3px solid #FFFFFF',
        borderRadius: '50%'
      }}
    >
      <circle cx='77' cy='77' r='77' fill='#F1EFEE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M97.9892 61.9678V48.3851C97.9892 45.4359 97.4064 42.5157 96.2744 39.7924C95.1423 37.0691 93.4834 34.5964 91.3927 32.5163C89.302 30.4362 86.8208 28.7897 84.0918 27.6715C81.3628 26.5532 78.4397 25.9853 75.4906 26.0003C69.5097 26.0454 63.7894 28.4543 59.5779 32.7012C55.3665 36.9482 53.0057 42.6886 53.0108 48.6696V61.9488H44V115.919H107V61.9678H97.9892ZM61.9458 48.3092C62.0768 44.8171 63.5561 41.5116 66.073 39.0873C68.5899 36.663 71.9485 35.3085 75.4431 35.3085C78.9377 35.3085 82.2963 36.663 84.8132 39.0873C87.3301 41.5116 88.8094 44.8171 88.9404 48.3092V61.797H62.0028L61.9458 48.3092ZM97.9892 106.946H53.0108V70.9027H89.0542L98.0461 61.9109L97.9892 106.946Z'
        fill='#0A98CD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M70.9947 79.9521H79.9865V97.9549H70.9947V79.9521Z'
        fill='#0A98CD'
      />
    </SvgIcon>
  )
}

export default ForgotPasswordIcon
