import { Errors } from 'features/ui'
import { useDispatch, useSelector } from 'react-redux'
import HeaderSentry from './header-sentry'
import { resetSignOut, signOut } from 'redux/features/auth/sign-out'
import { routeName } from 'routes'
import Box from '@mui/material/Box'
import { useEffect } from 'react'
import { resetSignIn } from 'redux/features/auth/sign-in'
import { client } from 'apollo-config'

const HandleError = () => {
  const { login } = routeName
  const { user } = useSelector((state) => state.currentUserInfo)
  const { loadingSignOut, successSignOut } = useSelector(
    (state) => state.signOut
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (successSignOut && !loadingSignOut) {
      dispatch(resetSignIn())
      dispatch(resetSignOut())
      localStorage.removeItem(`token-${process.env.REACT_APP_CURRENT_STAGE}`)
    }
  }, [loadingSignOut, successSignOut])

  const logOut = () => {
    dispatch(signOut())
    localStorage.removeItem(`token-${process.env.REACT_APP_CURRENT_STAGE}`)
    localStorage.removeItem('apollo-cache-persist')
    client.cache.reset()
    location.href = login
  }
  return (
    <Box sx={{ paddingBottom: { xs: '50px', xl: '0' } }}>
      <HeaderSentry
        logOut={logOut}
        user={user}
        loadingSignOut={loadingSignOut}
      />
      <Errors badRequest={true} />
    </Box>
  )
}

export default HandleError
