import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth } from 'aws-amplify'
import jwtDecode from 'jwt-decode'
import { store } from 'redux/store'
import { RetryLink } from '@apollo/client/link/retry'
import { SentryLink } from 'apollo-link-sentry'
import { onError } from '@apollo/client/link/error'
// import {
//   persistCache,
//   LocalStorageWrapper,
//   CachePersistor,
//   persistenceMapper
// } from 'apollo3-cache-persist'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_URL_APOLLO_GRAPHQL
})
const sentryLink = new SentryLink({
  uri: process.env.REACT_APP_URL_APOLLO_GRAPHQL,
  setTransaction: true,
  setFingerprint: true
})
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error
  }
})

const getAccessTokenPromise = async () => {
  const tokenName = `token-${process.env.REACT_APP_CURRENT_STAGE}`
  let token = localStorage.getItem(tokenName) || ''

  try {
    if (token === '') {
      store.dispatch({ type: 'auth/signOut/fulfilled' })
      return ''
    }
    const decoded = jwtDecode(token)
    const currentNumericDate = Math.round(Date.now() / 1000)

    if (currentNumericDate > decoded.exp) {
      const currentSession = await Auth.currentSession()
      token = currentSession.idToken.jwtToken

      localStorage.setItem(tokenName, token)
    }
  } catch (error) {
    store.dispatch({ type: 'auth/signOut/fulfilled' })
  }

  return token
}

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getAccessTokenPromise()
  return {
    headers: {
      ...headers,
      authorization: accessToken
    }
  }
})

export const cache = new InMemoryCache({})
// const persistCacheApollo = async () => {
//   await persistCache({
//     cache,
//     storage: new LocalStorageWrapper(window.localStorage)
//   })
// }

// persistCacheApollo()

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
}
const onErrorApollo = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }
})

export const client = new ApolloClient({
  // link: retryLink.concat(authLink.concat(httpLink)),
  link: ApolloLink.from([
    authLink,
    sentryLink,
    retryLink,
    onErrorApollo,
    httpLink
  ]),
  uri: process.env.REACT_APP_URL_APOLLO_GRAPHQL,
  cache,
  defaultOptions
})
