import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  successForgotPasswordSubmit: null,
  errorForgotPasswordSubmit: null,
  loadingForgotPasswordSubmit: false
}

export const forgotPasswordSubmit = createAsyncThunk(
  'auth/forgotPasswordSubmit',
  async ({ username, code, password }, { rejectWithValue }) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password)
      return true
    } catch (err) {
      switch (err.message) {
        case ' Invalid verification code provided, please try again.':
          return rejectWithValue(
            'El código ingresado no es correcto. Por favor ingresalo nuevamente.'
          )
        default:
          return rejectWithValue(
            'Ha ocurrido un error al intentar cambiar tu contraseña.'
          )
      }
    }
  }
)

export const forgotPasswordSubmitSlice = createSlice({
  name: 'forgotPasswordSubmit',
  initialState,
  reducers: {
    resetForgotPasswordSubmit: (state) => {
      state.successForgotPasswordSubmit = null
      state.errorForgotPasswordSubmit = null
      state.loadingForgotPasswordSubmit = false
    }
  },
  extraReducers: {
    [forgotPasswordSubmit.pending]: (state) => {
      state.loadingForgotPasswordSubmit = true
      state.successForgotPasswordSubmit = null
      state.errorForgotPasswordSubmit = null
    },
    [forgotPasswordSubmit.fulfilled]: (state, { payload }) => {
      state.loadingForgotPasswordSubmit = false
      state.successForgotPasswordSubmit = payload
    },
    [forgotPasswordSubmit.rejected]: (state, { payload }) => {
      state.loadingForgotPasswordSubmit = false
      state.errorForgotPasswordSubmit = payload
    }
  }
})

export const { resetForgotPasswordSubmit } = forgotPasswordSubmitSlice.actions

export default forgotPasswordSubmitSlice.reducer
