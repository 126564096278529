import { configureStore, combineReducers } from '@reduxjs/toolkit'
import signInReducer from 'redux/features/auth/sign-in'
import signUpReducer from 'redux/features/auth/sign-up'
import signOutReducer from 'redux/features/auth/sign-out'
import forgotPasswordReducer from 'redux/features/auth/forgot-password'
import forgotPasswordSubmitReducer from 'redux/features/auth/forgot-password-submit'
import changePasswordReducer from 'redux/features/auth/change-password'
import confirmSignUpReducer from 'redux/features/auth/confirm-signup'
import currentUserInfoReducer from 'redux/features/profile/current-user-info'
import updateUserAttributesReducer from 'redux/features/profile/update-user-attributes'
import resendSignUpReducer from 'redux/features/auth/resend-sign-up'
import userTempReducer from 'redux/features/auth/user-temp'
import productsReducer from 'redux/features/products'
import balanceReducer from 'redux/features/balance'

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

const reducer = combineReducers({
  signIn: signInReducer,
  signUp: signUpReducer,
  signOut: signOutReducer,
  forgotPassword: forgotPasswordReducer,
  forgotPasswordSubmit: forgotPasswordSubmitReducer,
  changePassword: changePasswordReducer,
  confirmSignUp: confirmSignUpReducer,
  currentUserInfo: currentUserInfoReducer,
  updateUserAttributes: updateUserAttributesReducer,
  resendSignUp: resendSignUpReducer,
  userTemp: userTempReducer,
  products: productsReducer,
  balance: balanceReducer
})

const persistConfig = {
  key: 'main-root',
  storage
}
const rootReducer = (state, action) => {
  if (action.type === 'auth/signOut/fulfilled') {
    state = undefined
  }

  return reducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer,
    preloadedState
  })
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)
