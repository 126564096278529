import Loader from 'features/ui/loader'
import { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { routeName } from './helpers'
import PrivateRoute from './private-route'
import PublicRoute from './public-route'
const Error = lazy(() => import('pages/shared/error'))
const Layout = lazy(() => import('pages/shared/layout'))
const Login = lazy(() => import('pages/public/login'))
const SignUp = lazy(() => import('pages/public/sign-up'))
const ForgotPassword = lazy(() => import('pages/public/forgot-password'))
const ChangePassword = lazy(() => import('pages/public/change-password'))
const ConfirmSignUp = lazy(() => import('pages/public/confirm-sign-up'))
const History = lazy(() => import('pages/private/history'))
const UnconfirmedAccount = lazy(() =>
  import('pages/public/unconfirmed-account.js')
)
const UpdatePassword = lazy(() => import('pages/private/update-password'))
const Profile = lazy(() => import('pages/private/profile'))
const Products = lazy(() => import('pages/private/products'))
const PayProduct = lazy(() => import('pages/private/pay-product'))
const PaymentStatus = lazy(() => import('pages/private/payment-status'))

const AppRoutes = () => {
  const {
    products,
    login,
    signup,
    forgotPassword,
    changePassword,
    updatePassword,
    confirmSignUp,
    unconfirmedAccount,
    profile,
    history,
    payProduct,
    paymentStatus
  } = routeName
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route element={<PrivateRoute />}>
            <Route path={payProduct} element={<PayProduct />} />
            <Route path={paymentStatus} element={<PaymentStatus />} />
            <Route path={products} element={<Products />} />
            <Route path={updatePassword} element={<UpdatePassword />} />
            <Route path={profile} element={<Profile />} />
            <Route path={history} element={<History />} />
          </Route>
          <Route element={<PublicRoute />}>
            <Route path={login} element={<Login />} />
            <Route path={signup} element={<SignUp />} />
            <Route path={forgotPassword} element={<ForgotPassword />} />
            <Route path={changePassword} element={<ChangePassword />} />
            <Route path={confirmSignUp} element={<ConfirmSignUp />} />
            <Route path={unconfirmedAccount} element={<UnconfirmedAccount />} />
          </Route>
        </Route>
        <Route path='*' element={<Error />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
