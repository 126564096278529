import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  successSignOut: null,
  errorSignOut: null,
  loadingSignOut: false
}

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { rejectWithValue }) => {
    try {
      await Auth.signOut()
      localStorage.removeItem(`token-${process.env.REACT_APP_CURRENT_STAGE}`)
      return true
    } catch (err) {
      return rejectWithValue('Ha ocurrido un error al intentar salir.')
    }
  }
)

export const signOutSlice = createSlice({
  name: 'signOut',
  initialState,
  reducers: {
    resetSignOut: (state) => {
      state.successSignOut = null
      state.errorSignOut = null
      state.loadingSignOut = false
    }
  },
  extraReducers: {
    [signOut.pending]: (state) => {
      state.loadingSignOut = true
      state.successSignOut = null
      state.errorSignOut = null
    },
    [signOut.fulfilled]: (state, { payload }) => {
      state.loadingSignOut = false
      state.successSignOut = payload
    },
    [signOut.rejected]: (state, { payload }) => {
      state.loadingSignOut = false
      state.errorSignOut = payload
    }
  }
})

export const {
  resetSignOut
} = signOutSlice.actions

export default signOutSlice.reducer
