import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  loadingChangePassword: false,
  successChangePassword: null,
  errorChangePassword: null
}

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const response = await Auth.changePassword(user, oldPassword, newPassword)
      return response
    } catch (err) {
      switch (err.message) {
        case 'Incorrect username or password.':
          return rejectWithValue(
            'La contraseña actual es incorrecta. Intentalo nuevamente.'
          )
        case 'Attempt limit exceeded, please try after some time.':
          return rejectWithValue(
            'Exediste el máximo de cambios. Por favor intentalo más tarde.'
          )
        default:
          return rejectWithValue(
            'Ha ocurrido un error al intentar actualizar tu contraseña.'
          )
      }
    }
  }
)
export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    resetChangePassword: (state) => {
      state.loadingChangePassword = false
      state.successChangePassword = null
      state.errorChangePassword = null
    }
  },
  extraReducers: {
    [changePassword.pending]: (state) => {
      state.loadingChangePassword = true
      state.successChangePassword = null
      state.errorChangePassword = null
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.loadingChangePassword = false
      state.successChangePassword = payload
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.loadingChangePassword = false
      state.errorChangePassword = payload
    }
  }
})

export const { resetChangePassword } = changePasswordSlice.actions

export default changePasswordSlice.reducer
