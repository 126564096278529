export const routeName = {
  products: '/products',
  login: '/login',
  history: '/history',
  signup: '/signup',
  unconfirmedAccount: '/unconfirmedaccount',
  forgotPassword: '/forgotpassword',
  changePassword: '/changepassword',
  updatePassword: '/updatepassword',
  confirmSignUp: '/confirmsignup',
  profile: '/profile',
  payProduct: '/payproduct',
  paymentStatus: '/paymentstatus'
}
