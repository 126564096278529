import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'

const ItemPassword = ({
  control,
  name,
  required = false,
  min = 0,
  pattern = null,
  validate = null,
  label,
  message,
  inputsWidth = { xs: '300px', md: '450px' }
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <Grid
      item
      sx={{
        width: inputsWidth,
        height: '56px'
      }}
    >
      <Controller
        name={name}
        control={control}
        rules={{
          required,
          pattern,
          validate,
          minLength: min
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={label}
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            fullWidth
            error={!!error}
            helperText={error ? message[error.type] : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </Grid>
  )
}

export default ItemPassword
