import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  loadingUpdateUserAttributes: false,
  successUpdateUserAttributes: null,
  errorUpdateUserAttributes: null
}

export const updateUserAttributesSubmit = createAsyncThunk(
  'auth/updateUserAttributesSubmit',
  async ({ userAttributes }, { rejectWithValue }) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, userAttributes)

      return userAttributes
    } catch (err) {
      switch (err.message) {
        case 'Invalid phone number format.':
          return rejectWithValue('Formato de télefono inválido')
        default:
          return rejectWithValue(
            'Ha ocurrido un error al intentar actualizar tus datos.'
          )
      }
    }
  }
)

export const updateUserAttibutesSlice = createSlice({
  name: 'updateUserAttributes',
  initialState,
  reducers: {
    resetUpdateUserAttributesSubmit: (state) => {
      state.loadingUpdateUserAttributes = false
      state.successUpdateUserAttributes = null
      state.errorUpdateUserAttributes = null
    }
  },
  extraReducers: {
    [updateUserAttributesSubmit.pending]: (state) => {
      state.loadingUpdateUserAttributes = true
      state.successUpdateUserAttributes = null
      state.errorUpdateUserAttributes = null
    },
    [updateUserAttributesSubmit.fulfilled]: (state, { payload }) => {
      state.loadingUpdateUserAttributes = false
      state.successUpdateUserAttributes = payload
    },
    [updateUserAttributesSubmit.rejected]: (state, { payload }) => {
      state.loadingUpdateUserAttributes = false
      state.errorUpdateUserAttributes = payload
    }
  }
})

export const { resetUpdateUserAttributesSubmit } =
  updateUserAttibutesSlice.actions

export default updateUserAttibutesSlice.reducer
