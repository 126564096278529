import SvgIcon from '@mui/material/SvgIcon'

const HistoryIcon = () => {
  return (
    <SvgIcon
      width='14'
      height='18'
      viewBox='0 0 14 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: '25px' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.11148 0L1.5574 1.55408H12.4459V15.5441H1.5574V1.55408L0 3.10817V17.0982H14V0H3.11148Z'
        fill='#FBFEFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.11157 6.21655H10.8919V7.7872H3.11157V6.21655Z'
        fill='#FBFEFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.11157 9.32446H10.8919V10.8819H3.11157V9.32446Z'
        fill='#FBFEFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.11157 12.426H10.8919V13.9801H3.11157V12.426Z'
        fill='#FBFEFF'
      />
    </SvgIcon>
  )
}

export default HistoryIcon
