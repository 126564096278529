import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  emailTemp: null,
  pinTemp: null
}

export const userTempSlice = createSlice({
  name: 'userTemp',
  initialState,
  reducers: {
    setEmailTemp: (state, { payload }) => {
      state.emailTemp = payload
    },
    resetEmailTemp: (state) => {
      state.emailTemp = null
    },
    setPinTemp: (state, { payload }) => {
      state.pinTemp = payload
    },
    resetPinTemp: (state) => {
      state.pinTemp = null
    }
  }
})

export const { resetEmailTemp, setEmailTemp, resetPinTemp, setPinTemp } =
  userTempSlice.actions

export default userTempSlice.reducer
