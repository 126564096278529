import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { routeName } from 'routes'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ProductsIcon from 'features/ui/products-icon'
import HistoryIcon from 'features/ui/history-icon'
import LogoutIcon from 'features/ui/logout-icon'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const { products, history } = routeName
const navItems = [
  {
    name: 'Productos',
    path: products,
    icon: <ProductsIcon />
  },
  {
    name: 'Historial',
    path: history,
    icon: <HistoryIcon />
  }
]

const styles = {
  containerNav: { width: '100%', display: 'flex', justifyContent: 'center' },
  firstItem: {
    display: 'flex',
    height: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: { xs: '300px', sm: '100%' },
    paddingLeft: { sm: '51px' },
    paddingRight: { sm: '51px' }
  },
  btnLogin: {
    width: '144px',
    height: '43px',
    border: '2px solid #0A98CD',
    borderRadius: '4px',
    textTransform: 'none',
    fontSize: '16px',
    marginRight: '10px',
    color: '#0A98CD',
    '&:hover': {
      border: '2px solid #0A98CD'
    }
  },
  btnRegister: {
    display: { xs: 'none', sm: 'inline' },
    padding: '13px 24px',
    borderRadius: '4px',
    textTransform: 'none',
    background: '#0A98CD',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#077199'
    }
  }
}
const PublicNavbar = () => {
  const { login, signup } = routeName
  const pathname = location.pathname
  return (
    <Grid container sx={styles.containerNav}>
      <Grid item sx={styles.firstItem}>
        <Box sx={{ opacity: pathname === login ? '0' : '1' }}>
          <Box
            sx={{
              width: '187px',
              height: '40px',
              marginLeft: { xs: '-10px', sm: '0' }
            }}
          >
            <img
              src='assets/images/kyosera.png'
              alt='kyosera icon'
              style={{
                width: '170px',
                height: '40px'
              }}
            />
          </Box>
        </Box>
        <Box>
          <Button href={login} variant='outlined' sx={styles.btnLogin}>
            Iniciar Sesión
          </Button>
          <Button variant='contained' sx={styles.btnRegister} href={signup}>
            Registrarse
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

const HeaderSentry = ({ user, logOut, loadingSignOut }) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  if (!user) return <PublicNavbar />
  return (
    <Box
      sx={{
        display: 'flex'
      }}
    >
      <CssBaseline />
      <AppBar
        position='static'
        color='secondary'
        open={open}
        sx={{
          boxShadow: 'none',
          width: { xs: '55px', sm: '64px' },
          marginTop: '15px',
          position: 'fixed',
          background: { xs: 'transparent', sm: '#282828' },
          display: open ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box>
          <IconButton
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              paddingLeft: '18px',
              color: { xs: '#282828', sm: '#F1EFEE' },
              ...(open && { display: 'none' })
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        sx={{
          border: 'none',
          display: { xs: !open ? 'none' : 'block', sm: 'block' }
        }}
      >
        <DrawerHeader
          sx={{
            background: { xs: !open ? '#F1EFEE' : '#282828', sm: '#282828' }
          }}
        >
          <IconButton onClick={handleDrawerClose} sx={{ color: '#F1EFEE' }}>
            {theme.direction === 'rtl'
              ? (
              <ChevronRightIcon />
                )
              : (
              <Box
                sx={{
                  width: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '56px',
                  marginTop: '25px'
                }}
              >
                <Box sx={{ display: !open ? 'none' : 'flex' }}>
                  <img
                    src='assets/images/kyosera-nav.png'
                    alt='kyosera icon'
                    style={{ width: '145px', height: '31px' }}
                  />
                </Box>
                <ChevronLeftIcon />
              </Box>
                )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            background: '#282828',
            height: '100%',
            display: { xs: !open ? 'none' : 'block', sm: 'block' }
          }}
        >
          {navItems.map(({ name, path, icon }) => (
            <ListItem
              key={name}
              disablePadding
              sx={{
                display: 'block',
                '&:hover': {
                  backgroundColor: ' #171717'
                }
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: '#F1EFEE'
                }}
                component='a'
                href={path}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: '#F1EFEE'
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  sx={{ opacity: open ? 1 : 0, color: '#F1EFEE' }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List
          sx={{
            display: 'flex',
            alignItems: 'end',
            background: '#282828',
            height: '100%'
          }}
        >
          <ListItem
            key={name}
            disablePadding
            sx={{
              display: 'block',
              '&:hover': {
                backgroundColor: ' #171717'
              }
            }}
            onClick={logOut}
            disabled={loadingSignOut}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                color: '#F1EFEE'
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: '#F1EFEE'
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary='Cerrar sesión'
                sx={{ opacity: open ? 1 : 0, color: '#F1EFEE' }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <DrawerHeader />
    </Box>
  )
}

export default HeaderSentry
