import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  user: null,
  loadingCurrentUserInfo: false,
  errorCurrentUserInfo: null
}

export const currentUserInfo = createAsyncThunk(
  'auth/currentUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const user = await Auth.currentUserInfo()
      const userInfo = { username: user.username, ...user.attributes }
      return userInfo
    } catch (err) {
      switch (err.message) {
        default:
          return rejectWithValue(
            'Ha ocurrido un error al intentar obtener la información del usuario.'
          )
      }
    }
  }
)

export const currentUserInfoSlice = createSlice({
  name: 'currentUserInfo',
  initialState,
  reducers: {
    resetCurrentUserInfo: (state) => {
      state.loadingCurrentUserInfo = false
      state.errorCurrentUserInfo = null
      state.user = null
    }
  },
  extraReducers: {
    [currentUserInfo.pending]: (state) => {
      state.loadingCurrentUserInfo = true
      state.errorCurrentUserInfo = null
    },
    [currentUserInfo.fulfilled]: (state, { payload }) => {
      state.loadingCurrentUserInfo = false
      state.user = {
        ...state.user,
        ...payload
      }
    },
    [currentUserInfo.rejected]: (state, { payload }) => {
      state.loadingCurrentUserInfo = false
      state.errorCurrentUserInfo = payload
    }
  }
})

export const { resetCurrentUserInfo } = currentUserInfoSlice.actions

export default currentUserInfoSlice.reducer
