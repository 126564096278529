import SvgIcon from '@mui/material/SvgIcon'

const ErrorIcon = () => {
  return (
    <SvgIcon
      width='200'
      height='200'
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: { xs: '90px', sm: '180px' } }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200ZM99.6133 80.6562L85.44 54.6057H61.0523L85.2816 96.4923L59.9438 140.279H84.3314L99.7717 112.724L115.212 140.279H139.6L114.262 96.4923L138.491 54.6057H114.183L99.6133 80.6562Z'
        fill='#FF5A54'
      />
    </SvgIcon>
  )
}

export default ErrorIcon
