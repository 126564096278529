import Alert from '@mui/material/Alert'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
const Alerts = ({
  showSuccessAlert = false,
  setShowSuccessAlert,
  errorAlert = false,
  setError,
  messageError = '',
  setmessageError,
  left = '0'
}) => {
  const { user } = useSelector((state) => state.currentUserInfo)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showSuccessAlert) return
      setShowSuccessAlert(false)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [showSuccessAlert])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!errorAlert) return
      setError(false)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [errorAlert])

  useEffect(() => {
    if (!messageError) return
    const timer = setTimeout(() => {
      setmessageError('')
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [messageError])

  if (messageError) {
    return (
      <Alert
        variant='filled'
        severity='error'
        sx={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left:
            user && user['custom:pin'] ? { xs: '-125px', sm: '-70px' } : '0',
          right: '0',
          top: '10vh',
          width: '250px'
        }}
      >
        {messageError}
      </Alert>
    )
  }
  if (errorAlert) {
    return (
      <Alert
        variant='filled'
        severity='error'
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        Algo salio mal
      </Alert>
    )
  }
  if (showSuccessAlert) {
    return (
      <Alert
        variant='filled'
        severity='success'
        sx={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left,
          right: '0',
          top: '10vh',
          width: '250px'
        }}
      >
        Cambio exitoso
      </Alert>
    )
  }
}

export default Alerts
