import SvgIcon from '@mui/material/SvgIcon'

const UserIcon = ({ fontSize }) => {
  return (
    <SvgIcon
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: fontSize ? '100px' : { xs: '40px', sm: '50px' } }}
    >
      <circle cx='25' cy='25' r='25' fill='#282828' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6559 28.1873V37.707H13.896V31.3204H32.6816L35.8148 28.1873V37.707H39.0682V28.1873H10.6559Z'
        fill='white'
      />
      <path
        d='M24.8654 24.4725C23.3776 24.4725 21.9233 24.0313 20.6863 23.2048C19.4493 22.3782 18.4851 21.2034 17.9157 19.8289C17.3464 18.4544 17.1974 16.9419 17.4877 15.4827C17.7779 14.0235 18.4944 12.6832 19.5464 11.6312C20.5984 10.5792 21.9387 9.86277 23.3979 9.57252C24.8571 9.28227 26.3695 9.43123 27.7441 10.0006C29.1186 10.5699 30.2934 11.5341 31.12 12.7711C31.9465 14.0082 32.3877 15.4625 32.3877 16.9502C32.3841 18.9442 31.5905 20.8555 30.1805 22.2654C28.7706 23.6753 26.8594 24.469 24.8654 24.4725ZM24.8654 11.8263C23.852 11.8263 22.8613 12.1268 22.0187 12.6898C21.1761 13.2529 20.5193 14.0531 20.1315 14.9894C19.7437 15.9257 19.6422 16.9559 19.8399 17.9499C20.0376 18.9438 20.5257 19.8568 21.2423 20.5734C21.9589 21.29 22.8718 21.7781 23.8658 21.9758C24.8597 22.1735 25.89 22.072 26.8263 21.6842C27.7625 21.2964 28.5628 20.6396 29.1258 19.797C29.6889 18.9543 29.9894 17.9637 29.9894 16.9502C29.9876 15.5918 29.4472 14.2896 28.4866 13.329C27.5261 12.3685 26.2238 11.8281 24.8654 11.8263Z'
        fill='white'
      />
    </SvgIcon>
  )
}

export default UserIcon
