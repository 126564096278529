import SvgIcon from '@mui/material/SvgIcon'

const LogoutIcon = () => {
  return (
    <SvgIcon
      width={19}
      height={20}
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: '14px' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.9432 10.2021L18.9432 0.702148L17.0392 0.702148L17.0392 19.7021L18.9432 19.7021L18.9432 10.2021Z'
        fill='#545454'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.38774 14.7115L3.55212 10.8759L13.2871 10.8759L14.662 9.50689L3.55212 9.50689L7.38774 5.67127L6.41862 4.70215L0.922079 10.187L6.41862 15.6807L7.38774 14.7115Z'
        fill='#545454'
      />
    </SvgIcon>
  )
}

export default LogoutIcon
