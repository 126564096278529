import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  loadingConfirmSignUp: false,
  successConfirmSignUp: null,
  errorConfirmSignUp: null
}

export const confirmSignUp = createAsyncThunk(
  'auth/confirmSignUp',
  async ({ username, confirmationCode }, { rejectWithValue }) => {
    try {
      await Auth.confirmSignUp(username, confirmationCode)
      return true
    } catch (err) {
      switch (err.message) {
        case 'Invalid code provided, please request a code again.':
          return rejectWithValue(
            'El código ingresado no es correcto. Por favor ingresalo nuevamente.'
          )
        default:
          return rejectWithValue('Ha ocurrido un error.')
      }
    }
  }
)

export const confirmSignUpSlice = createSlice({
  name: 'confirmSignUp',
  initialState,
  reducers: {
    resetConfirmSignUp: (state) => {
      state.successConfirmSignUp = null
      state.errorConfirmSignUp = null
      state.loadingConfirmSignUp = false
    }
  },
  extraReducers: {
    [confirmSignUp.pending]: (state) => {
      state.loadingConfirmSignUp = true
      state.successConfirmSignUp = null
      state.errorConfirmSignUp = null
    },
    [confirmSignUp.fulfilled]: (state, { payload }) => {
      state.loadingConfirmSignUp = false
      state.successConfirmSignUp = payload
    },
    [confirmSignUp.rejected]: (state, { payload }) => {
      state.loadingConfirmSignUp = false
      state.errorConfirmSignUp = payload
    }
  }
})

export const { resetConfirmSignUp } = confirmSignUpSlice.actions

export default confirmSignUpSlice.reducer
