import SvgIcon from '@mui/material/SvgIcon'

const ProductsIcon = () => {
  return (
    <SvgIcon
      width='21'
      height='16'
      viewBox='0 0 21 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: '25px' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7599 1.8931H18.8388L17.3267 9.44947H7.36385L6.61383 1.8931L6.4173 0H0L1.89309 1.8931H4.70066L5.13384 6.20071L5.64721 11.3426H18.8829L20.7599 1.8931Z'
        fill='#F1EFEE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.60572 13.2316C6.60572 12.7313 6.80419 12.2514 7.1576 11.8972C7.511 11.5431 7.99048 11.3436 8.4908 11.3425C8.99182 11.3425 9.47231 11.5416 9.82658 11.8958C10.1809 12.2501 10.3799 12.7306 10.3799 13.2316V13.2316C10.3804 13.48 10.3319 13.7261 10.2372 13.9558C10.1425 14.1854 10.0035 14.3942 9.828 14.57C9.65253 14.7458 9.44411 14.8854 9.21466 14.9805C8.9852 15.0757 8.73922 15.1247 8.4908 15.1247V15.1247C8.24272 15.1242 7.99717 15.0748 7.76818 14.9794C7.53918 14.884 7.33122 14.7444 7.15618 14.5686C6.98113 14.3928 6.84242 14.1843 6.74797 13.9549C6.65352 13.7255 6.60519 13.4797 6.60572 13.2316V13.2316Z'
        fill='#F1EFEE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1543 13.2316C14.1543 12.9835 14.2032 12.7379 14.2981 12.5087C14.393 12.2795 14.5322 12.0712 14.7076 11.8958C14.883 11.7204 15.0913 11.5813 15.3205 11.4863C15.5497 11.3914 15.7953 11.3425 16.0434 11.3425V11.3425C16.5444 11.3425 17.0249 11.5416 17.3792 11.8958C17.7335 12.2501 17.9325 12.7306 17.9325 13.2316C17.933 13.48 17.8845 13.7261 17.7898 13.9558C17.6951 14.1854 17.5561 14.3942 17.3806 14.57C17.2051 14.7458 16.9967 14.8854 16.7673 14.9805C16.5378 15.0757 16.2918 15.1247 16.0434 15.1247V15.1247C15.795 15.1247 15.549 15.0757 15.3196 14.9805C15.0901 14.8854 14.8817 14.7458 14.7062 14.57C14.5307 14.3942 14.3916 14.1854 14.2969 13.9558C14.2022 13.7261 14.1538 13.48 14.1543 13.2316V13.2316Z'
        fill='#F1EFEE'
      />
    </SvgIcon>
  )
}

export default ProductsIcon
