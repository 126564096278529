import { useEffect, useRef, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronRightIcon from '@mui/icons-material/ChevronLeft'
import { Box } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { routeName } from 'routes'
import ProductsIcon from './products-icon'
import HistoryIcon from './history-icon'

const { products, history } = routeName
const navItems = [
  {
    name: 'Productos',
    path: products,
    icon: <ProductsIcon />
  },
  {
    name: 'Historial',
    path: history,
    icon: <HistoryIcon />
  }
]

const styles = {
  containerNav: { width: '100%', display: 'flex', justifyContent: 'center' },
  firstItem: {
    display: 'flex',
    height: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: { xs: '300px', sm: '100%' },
    paddingLeft: { sm: '51px' },
    paddingRight: { sm: '51px' }
  },
  btnLogin: {
    width: '144px',
    height: '43px',
    border: '2px solid #0A98CD',
    borderRadius: '4px',
    textTransform: 'none',
    fontSize: '16px',
    marginRight: '10px',
    '&:hover': {
      border: '2px solid #0A98CD'
    }
  }
}

const registerDisplay = (pathname, login) => {
  const btnRegister = {
    display: pathname === login ? 'inline' : 'none',
    width: '144px',
    textAlign: 'center',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '4px',
    textTransform: 'none',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#077199'
    }
  }
  return btnRegister
}

const PublicNavbar = () => {
  const { login, signup } = routeName
  const { pathname } = useLocation()
  return (
    <Grid container sx={styles.containerNav}>
      <Grid item sx={styles.firstItem}>
        <Box
          sx={{
            opacity: pathname === login ? '0' : '1'
          }}
        >
          <Box
            sx={{
              width: '187px',
              height: '40px',
              marginLeft: { xs: '-10px', sm: '0' }
            }}
          >
            <img
              src='assets/images/kyosera.png'
              alt='kyosera icon'
              style={{
                width: '170px',
                height: '40px'
              }}
            />
          </Box>
        </Box>
        <Box>
          {pathname !== login && (
            <Button
              variant='outlined'
              color='primary'
              component={Link}
              to={login}
              sx={styles.btnLogin}
            >
              Iniciar Sesión
            </Button>
          )}
          <Button
            variant='contained'
            color='primary'
            component={Link}
            to={signup}
            sx={registerDisplay(pathname, login)}
          >
            Registrarse
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

const CompanyImage = () => {
  return (
    <Box
      sx={{
        width: '200px',
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <img
        src='/assets/images/kyosera-nav.png'
        alt='kyosera icon'
        style={{ width: '145px', height: '31px' }}
      />
      <ChevronRightIcon />
    </Box>
  )
}

const NavigationBar = ({ user }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [showWhiteNav, setShowWhiteNav] = useState(false)
  const { pathname } = useLocation()
  const navRef = useRef()
  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const handleClose = () => {
    setIsCollapsed(true)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsCollapsed(true)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [navRef])

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset > 0) {
        setShowWhiteNav(true)
      } else {
        setShowWhiteNav(false)
      }
    })
  }, [])
  if (!user) return <PublicNavbar />
  return (
    <Box
      sx={{
        width: { xs: isCollapsed ? '0' : '100vw', lg: '0' },
        maxWidth: '100vw',
        minHeight: '100vh',
        background: 'hsla(0, 0%, 0%, 0.4)',
        position: !isCollapsed ? 'fixed' : 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        zIndex: '5000'
      }}
    >
      <Drawer
        variant='permanent'
        ref={navRef}
        sx={{
          maxWidth: '240px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isCollapsed ? 64 : 240,
            boxSizing: 'border-box',
            transition: 'width .3s ease-out',
            overflowX: 'hidden',
            border: 'none',
            backgroundColor: {
              xs: isCollapsed ? 'transparent' : 'dark.main',
              sm: 'dark.main'
            }
          }
        }}
      >
        <Box
          sx={{
            width: 'auto',
            paddingTop: '3px',
            display: 'flex',
            justifyContent: isCollapsed ? 'center' : 'center'
          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleCollapse}
            disableRipple
            sx={{
              color: {
                xs: isCollapsed ? 'dark.main' : 'whiteFont.main',
                sm: 'whiteFont.main'
              }
            }}
          >
            {isCollapsed
              ? (
              <>
                <Box
                  sx={{
                    background: showWhiteNav ? '#ffffff' : 'transparent',
                    width: '50px',
                    height: '50px',
                    borderRadius: '4px',
                    display: { xs: 'flex', sm: 'none' },
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <MenuIcon />
                </Box>
                <MenuIcon sx={{ display: { xs: 'none', sm: 'flex' } }} />
              </>
                )
              : (
              <CompanyImage />
                )}
          </IconButton>
        </Box>
        <List>
          {navItems.map(({ name, path, icon }) => (
            <ListItem
              key={name}
              onClick={handleClose}
              disablePadding
              sx={{
                display: { xs: !isCollapsed ? 'block' : 'none', sm: 'block' },
                width: !isCollapsed ? '90%' : '45px',
                height: '48px',
                margin: '0 auto',
                borderRadius: '4px',
                backgroundColor:
                  pathname === path ? 'dark.light' : 'transparent',
                '&:hover': {
                  backgroundColor:
                    pathname === path ? 'dark.ultraLight' : 'dark.light'
                }
              }}
            >
              <ListItemButton
                disableRipple
                component={Link}
                to={path}
                sx={{
                  minHeight: 48,
                  display: 'flex',
                  justifyContent: !isCollapsed ? 'start' : 'center',
                  color: '#F1EFEE'
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: !isCollapsed ? 3 : 'auto',
                    justifyContent: 'center',
                    color: '#F1EFEE',
                    fontSize: '25px'
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontWeight: pathname === path ? '500' : '400',
                      fontSize: '14px',
                      display: !isCollapsed ? 'flex' : 'none',
                      color: '#F1EFEE',
                      padding: '0'
                    }}
                  >
                    {name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  )
}
export default NavigationBar
