import { routeName } from './helpers'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PublicRoute = () => {
  const { profile } = routeName

  const { user } = useSelector((state) => state.signIn)

  if (user && !user['custom:pin']) return <Navigate to={profile} />
  return <Outlet />
}

export default PublicRoute
