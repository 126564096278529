import Box from '@mui/material/Box'

export const ContainerView = ({ children, user }) => {
  return (
    <Box
      sx={{
        minHeight: user
          ? 'calc(100vh - 60px - 116px)'
          : 'calc(100vh - 100px - 116px)',
        position: 'relative'
      }}
    >
      {children}
    </Box>
  )
}
