import SvgIcon from '@mui/material/SvgIcon'

const ReloadIcon = () => {
  return (
    <SvgIcon
      width={20}
      height={17}
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1724 4.44092L15.5556 6.36687L17.4815 0.98371'
        stroke='white'
        strokeWidth={2}
      />
      <path
        d='M15.7985 8.47192C15.7985 12.458 12.5671 15.6894 8.58103 15.6894C4.59495 15.6894 1.36359 12.458 1.36359 8.47192'
        stroke='white'
        strokeWidth={2}
      />
      <path
        d='M1.36355 8.47192C1.36355 4.48584 4.59491 1.25448 8.581 1.25448C11.7366 1.25448 14.4193 3.27969 15.4001 6.10129'
        stroke='white'
        strokeWidth={2}
      />
    </SvgIcon>
  )
}

export default ReloadIcon
