import { regExp } from 'utils/regular-expresions'

export const messageReg = {
  name: {
    required: 'El nombre es requerido',
    minLength: 'Al menos 3 letras',
    maxLength: 'Máximo permitido 50 caracteres',
    validate: 'Escriba un nombre válido'
  },
  email: {
    required: 'El correo es requerido',
    pattern: 'Correo debe contener “@”'
  },
  password: {
    required: 'La contraseña es requerida',
    minLength: 'Mínimo 8 caracteres',
    pattern: 'Contraseña debe contener un número'
  },
  confirmPassword: {
    validate: 'Las contraseñas no coinciden'
  }
}

export const regExpReg = {
  name: /^[A-Za-z\s]*$/,
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  password: regExp.password
}
