import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import InfoErrorIcon from './info-error-icon'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { routeName } from 'routes'

const Errors = ({ badRequest = null }) => {
  const { products } = routeName
  return (
    <Grid
      container
      alignItems='center'
      justifyContent='start'
      direction='column'
    >
      <Stack
        spacing={3}
        sx={{
          background: '#ffffff',
          width: { xs: '320px', sm: '490px', md: '603px', lg: '945px' },
          height: { xs: '420px', md: '665px' },
          marginTop: { xs: '60px', sm: '87px', lg: '30px' },
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Grid
          item
          sx={{
            width: { xs: '300px', sm: '600px' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: { xs: '60px', md: '120px' }
          }}
        >
          <InfoErrorIcon />
          <Typography
            variant='h1'
            sx={{
              width: { xs: '280px', sm: '450px' },
              fontSize: { xs: '24px', lg: '40px' },
              fontWeight: '700',
              color: '#30333C',
              padding: '40px 0 30px 0'
            }}
            align='center'
          >
            {badRequest
              ? 'Página en mantenimiento'
              : 'La página no fue encontrada'}
          </Typography>
          {badRequest
            ? (
            <Typography
              variant='subtitle1'
              sx={{
                width: { xs: '280px', sm: '450px' },
                fontSize: { xs: '16px', lg: '20px' },
                fontWeight: '400',
                color: '#30333C',
                padding: '0 0 50px 0'
              }}
              align='center'
            >
              Vuelve a intentarlo mas tarde
            </Typography>
              )
            : (
            <Button
              component={Link}
              sx={{
                background: '#0A98CD',
                height: '56px',
                color: '#ffffff',
                textTransform: 'none',
                width: { xs: '260px', sm: '350px', md: '450px' },
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#077199'
                }
              }}
              to={products}
            >
              Ver productos
            </Button>
              )}
        </Grid>
      </Stack>
    </Grid>
  )
}

export default Errors
