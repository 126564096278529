import { Amplify } from 'aws-amplify'

const config = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    identityPoolRegion: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
}

Amplify.configure(config)
