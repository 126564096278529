import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  product: {},
  quantity: 0,
  price: 0
}
export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      state.product = action.payload
    },
    increment: (state, action) => {
      state.quantity =
        action.payload === 0
          ? state.product.minNumberOfItem + 1
          : action.payload + 1
      state.price = state.product.amountByItem * state.quantity
    },
    incrementByAmount: (state, action) => {
      state.quantity = action.payload
      state.price = state.product.amountByItem * state.quantity
    },
    decrement: (state, action) => {
      state.quantity = action.payload - 1
      state.price = state.product.amountByItem * state.quantity
    },
    resetQuantity: (state) => {
      state.quantity = 0
    },
    resetPrice: (state) => {
      state.price = 0
    }
  }
})

export const {
  addProduct,
  increment,
  incrementByAmount,
  decrement,
  resetQuantity,
  resetPrice
} = productsSlice.actions

export default productsSlice.reducer
