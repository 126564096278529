import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'

const ItemInput = ({
  control,
  name,
  required = false,
  min = 0,
  max = 255,
  pattern = null,
  validate = null,
  label,
  message,
  inputsWidth = { xs: '300px', md: '450px' }
}) => {
  return (
    <Grid item sx={{ width: inputsWidth, height: '56px' }}>
      <Controller
        name={name}
        control={control}
        rules={{
          required,
          pattern,
          validate,
          minLength: min,
          maxLength: max
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={label}
            variant='outlined'
            fullWidth
            error={!!error}
            helperText={error ? message[error.type] : null}
          />
        )}
      />
    </Grid>
  )
}

export default ItemInput
