import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  user: null,
  errorSignIn: null,
  loadingSignIn: false
}

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const user = await Auth.signIn(email.toLowerCase(), password)
      const userInfo = { username: user.username, ...user.attributes }
      const currentSession = await Auth.currentSession()
      localStorage.setItem(`token-${process.env.REACT_APP_CURRENT_STAGE}`, currentSession.idToken.jwtToken)
      return userInfo
    } catch (err) {
      switch (err.message) {
        case 'Username should be either an email or a phone number.':
          return rejectWithValue('El correo no es correcto')
        case 'Password did not conform with policy: Password not long enough':
          return rejectWithValue('Contraseña no cumple las politicas')
        case 'User is not confirmed.':
          return rejectWithValue('Usuario no ha confirmado la cuenta.')
        case 'Incorrect username or password.':
          return rejectWithValue('Usuario o contraseña invalidas.')
        case 'User does not exist.':
          return rejectWithValue('Usuario no existe.')
        case 'PreAuthentication failed with error User has exceeded the number of attempts.':
          return rejectWithValue(
            'La cantidad de intentos ha excedido el máximo.  Por favor, cambie su contraseña.'
          )
        default:
          return rejectWithValue('Ha ocurrido un error.')
      }
    }
  }
)

export const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    resetSignIn: (state) => {
      state.user = null
      state.loadingSignIn = false
      state.errorSignIn = null
    }
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.loadingSignIn = true
      state.user = null
      state.errorSignIn = null
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = payload
    },
    [signIn.rejected]: (state, { payload }) => {
      state.loadingSignIn = false
      state.errorSignIn = payload
    }
  }
})

export const { resetSignIn } = signInSlice.actions

export default signInSlice.reducer
