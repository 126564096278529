import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

const initialState = {
  successResendSignUp: null,
  errorResendSignUp: null,
  loadingResendSignUp: false
}

export const resendSignUp = createAsyncThunk(
  'auth/resendSignUp',
  async ({ username }, { rejectWithValue }) => {
    try {
      await Auth.resendSignUp(username)
      return true
    } catch (err) {
      return rejectWithValue(
        'Ha ocurrido un error al intentar renviar un nuevo código.'
      )
    }
  }
)
export const resendSignUpSlice = createSlice({
  name: 'resendSignUp',
  initialState,
  reducers: {
    resetResendSignUp: (state) => {
      state.successResendSignUp = null
      state.errorResendSignUp = null
      state.loadingResendSignUp = false
    }
  },
  [resendSignUp.pending]: (state) => {
    state.loadingResendSignUp = true
    state.successResendSignUp = null
    state.errorResendSignUp = null
  },
  [resendSignUp.fulfilled]: (state, { payload }) => {
    state.loadingResendSignUp = false
    state.successResendSignUp = payload
  },
  [resendSignUp.rejected]: (state, { payload }) => {
    state.loadingResendSignUp = false
    state.errorResendSignUp = payload
  }
})

export const { resetResendSignUp } = resendSignUpSlice.actions

export default resendSignUpSlice.reducer
