import SvgIcon from '@mui/material/SvgIcon'

const UserMenuIcon = () => {
  return (
    <SvgIcon
      width={19}
      height={19}
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: '14px' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.89032 12.6611V18.7205H2.95265V14.6554H14.9099L16.9043 12.6611V18.7205H18.9751V12.6611H0.89032Z'
        fill='#545454'
      />
      <path
        d='M9.93482 10.2968C8.98784 10.2968 8.06214 10.016 7.27475 9.48984C6.48737 8.96372 5.87365 8.21593 5.51125 7.34103C5.14886 6.46613 5.05405 5.50342 5.23879 4.57463C5.42354 3.64585 5.87957 2.7927 6.54919 2.12308C7.21881 1.45346 8.07193 0.997454 9.00072 0.812707C9.92951 0.62796 10.8922 0.722774 11.7671 1.08517C12.642 1.44756 13.3898 2.06128 13.9159 2.84867C14.442 3.63605 14.7228 4.56175 14.7228 5.50873C14.7206 6.7779 14.2154 7.99445 13.318 8.89189C12.4205 9.78933 11.204 10.2945 9.93482 10.2968ZM9.93482 2.24727C9.28976 2.24727 8.65918 2.43854 8.12284 2.79691C7.58649 3.15529 7.16848 3.66466 6.92163 4.26062C6.67477 4.85658 6.61019 5.51235 6.73603 6.14501C6.86188 6.77767 7.1725 7.35882 7.62863 7.81494C8.08475 8.27107 8.66587 8.5817 9.29854 8.70754C9.9312 8.83339 10.587 8.7688 11.1829 8.52195C11.7789 8.27509 12.2883 7.85706 12.6466 7.32071C13.005 6.78436 13.1963 6.15379 13.1963 5.50873C13.1952 4.64408 12.8512 3.81516 12.2398 3.20376C11.6284 2.59236 10.7995 2.24839 9.93482 2.24727Z'
        fill='#2B2B2B'
      />
    </SvgIcon>
  )
}

export default UserMenuIcon
