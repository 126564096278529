import SvgIcon from '@mui/material/SvgIcon'

const PassMenuIcon = () => {
  return (
    <SvgIcon
      width={14}
      height={20}
      viewBox='0 0 14 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize: '14px' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6846 8.33197V5.46194C11.6846 4.83876 11.5615 4.22173 11.3223 3.64629C11.0831 3.07086 10.7325 2.54836 10.2907 2.10883C9.84898 1.6693 9.3247 1.3214 8.74805 1.08511C8.17141 0.848831 7.55378 0.728826 6.93061 0.731995C5.66684 0.74152 4.45813 1.25052 3.56825 2.14792C2.67836 3.04531 2.17952 4.25825 2.18061 5.52206V8.32796H0.276611V19.7319H13.5886V8.33197H11.6846ZM4.06859 5.44591C4.09626 4.70801 4.40883 4.00956 4.94066 3.4973C5.47249 2.98504 6.18217 2.69884 6.92058 2.69884C7.65899 2.69884 8.36867 2.98504 8.9005 3.4973C9.43233 4.00956 9.7449 4.70801 9.77257 5.44591V8.29589H4.08063L4.06859 5.44591ZM11.6846 17.8359H2.18061V10.2199H9.79662L11.6966 8.31995L11.6846 17.8359Z'
        fill='#545454'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.98065 12.1321H7.88064V15.9361H5.98065V12.1321Z'
        fill='#545454'
      />
    </SvgIcon>
  )
}

export default PassMenuIcon
